(function () {
    waterwheel();
    gallery();
    layout();
    $(window).on('resize', function(){
        layout();
    });
    
    navbar();
}());
var focus = false;
function gallery() {
    if ($('.gallery-items').length <= 0) {
        return;
    }
    $gallerySlick = $('.gallery-items').slick({
        slidesToShow: 1,
        vertical: true,
        verticalSwiping: true,
        arrows: false,
        draggable: false,
        swipe: false,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    vertical: false,
                    draggable: false,
                    swipe: false
                }
            }
        ]
    });


    var mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll" : "mousewheel";
    $('.gallery-container').bind(mousewheelevt, function(e){

        if (!focus) {
            return;
        }
        e.preventDefault();
        var evt = window.event || e
        evt = evt.originalEvent ? evt.originalEvent : evt;
        var delta = evt.detail ? evt.detail*(-40) : evt.wheelDelta
        if(delta > 0) {
            
            $('.gallery-items.left').slick('slickPrev');
            $('.gallery-items.right').slick('slickNext');
        }
        else {
            $('.gallery-items.left').slick('slickNext');
            $('.gallery-items.right').slick('slickPrev');
        }
    });

    // $('.gallery-item').mousedown(function() {
    //     isDragging = false;
    // })
    // .mousemove(function() {
    //     isDragging = true;
    //  })
    // .mouseup(function() {
    //     var wasDragging = isDragging;
    //     isDragging = false;
    //     console.log("HIdas")

    //     if (!wasDragging) {
    //         console.log("HI")
    //     }
    // });

    $('.gallery-container').on('mouseleave', function() {
        focus = false;
        $('.scroll-info-container').removeClass('clicked');
    });
    $('.scroll-info-container').on('click', function() {
        focus = true;
        $('.scroll-info-container').addClass('clicked');
    });
}
function navbar() {
    $('.hamburger, .nav-overlay').on('click', function() {
        $('.hamburger').toggleClass('open');
        $('nav .nav.navbar-nav').toggleClass('open');
        $('.nav-overlay').toggleClass('open');
    });
    var mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll" : "mousewheel";
    $('.nav-overlay').bind(mousewheelevt, function(e){
        e.preventDefault();
    });
    $(document).scroll(function() { 
        if($(window).scrollTop() <= 10) {
            $('.navbar').removeClass('scrolled');
        } else {
            $('.navbar').addClass('scrolled');
        }
    });
}
function layout() {
    if (window.matchMedia('(max-width: 767px)').matches) {
        return;
    }
    $containers = $('.container:not(".container--fullwidth-right")');
    if ($containers && $containers.length > 0) {
        $marginLeft = $containers.first().offset().left;
        $('.container--fullwidth-right').css('width', $('.container').first().outerWidth() + $marginLeft);
    }
}
function waterwheel() {
    /*--water_wheel_carousel--*/
    if ($('#carousel').length <= 0) {
        return;
    }
    if (window.matchMedia("(max-width: 767px)").matches) {
        var carousel = $("#carousel").waterwheelCarousel({
            flankingItems: 2,
            separation: 300,
            speed:250,
        });
    } else {
        var carousel = $("#carousel").waterwheelCarousel({
            flankingItems: 2,
            separation: 330,
            speed:250,
        });
    }


    $("#carousel").swipe( {
    swipeStatus:function(event, phase, direction, distance)
    {
        if (phase=="end"){
            if(direction == "right") { 
                carousel.prev();
            }
            else if(direction =="left") {
                carousel.next();
            }else { return false;}
        }
    },
    triggerOnTouchEnd:false,
    threshold:100
    });
    $('#prev').on('click', function (){
    carousel.prev();
    return false;
    });
    $('#next').on('click', function (){
    carousel.next();
    return false;
    });
    /*--water_wheel carousel_script_end--*/
}


// CRUD
var notify;
function showNotify(title, message, timer) {
	var message = (_.isUndefined(message) ? '' : message);
	var timer = (_.isUndefined(timer) ? 4000 : timer);
	if (!_.isUndefined(notify)) {
		notify.close();
	}
	notify = $.notify({
		icon: '',
		title: title,
		message: message,
	}, {
			type: "primary",
			animate: {
				enter: 'animated fadeInUp',
				exit: 'animated fadeOutDown'
			},
			placement: {
				from: "bottom",
				align: "right"
			},
			timer: timer
		});
};

//contact page
var parselyForm = $('form.contact-form');
var requiredContactFields = ['n_con_ame', 'm_con_essage', 'e_con_mail', 'c_con_ontact', 's_con_teel'];

if (parselyForm.length > 0) {
	parselyForm.submit(function (e) {
		e.preventDefault();
		var isValid = validateForm();

		if (isValid) {
            grecaptcha.reset();
            grecaptcha.execute();
			// var url = parselyForm.attr('action');
            // var data = parselyForm.serialize();
			// var method = parselyForm.find('input[name="_method"]').val();
			// var type = (_.isUndefined(method) ? 'POST' : method);
			// var form = $('form.contact-form');
			// submitForm(url, data, type, form);

			return false;
		}
	});

	// parselyForm.on('form:submit', function () {
	// 	// showNotify('Sending Inquiry.');

	// 	var url = parselyForm.attr('action');
	// 	var data = parselyForm.serialize();
	// 	var method = parselyForm.find('input[name="_method"]').val();
	// 	var type = (_.isUndefined(method) ? 'POST' : method);
	// 	var form = $('form.contact-form');
	// 	submitForm(url, data, type, form);

	// 	return false;
	// });
}

function formSubmit() {
    var url = parselyForm.attr('action');
    var data = parselyForm.serialize();
    var method = parselyForm.find('input[name="_method"]').val();
    var type = (_.isUndefined(method) ? 'POST' : method);
    var form = $('form.contact-form');
    submitForm(url, data, type, form);
}



function validateForm() {
	//check all inputs
	var isValid = 1;
	$('.contact-form input').each(function () {
		// console.log($.inArray($(this).attr('name'), requiredContactFields));
		if ($.inArray($(this).attr('name'), requiredContactFields) > -1) {
			if ($(this).val() == '') {
				$(this).addClass('error');
				isValid = 0;
			}
		}

		if ($(this).attr('name') == 'e_con_mail') {
			var validEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
			if (!validEmail.test($(this).val())) {
				$(this).addClass('error');
                isValid = 0;
			}
		}

		// if ($('.steel-input').val().toLowerCase() == "steel load" || $('.steel-input').val().toLowerCase() == "others") {
		// 	if ($.inArray($(this).attr('name'), ['l_con_ength', 'w_con_idth', 'h_con_eight']) > -1) {
		// 		if ($(this).val() == '') {
		// 			$(this).addClass('error');
		// 			isValid = 0;
		// 		}
		// 	}
		// 	if ($(this).attr('name') == "c_con_hoice") {
		// 		if ($(this).val() == '') {
		// 			$(this).addClass('error');
		// 			isValid = 0;
		// 		}
		// 	}
		// } else {
		// 	if ($(this).attr('name') == "c_con_hoice") {
		// 		if ($(this).val() == '') {
		// 			$(this).addClass('error');
		// 			isValid = 0;
		// 		}
		// 	}
		// }
	});

	$('.contact-form textarea').each(function () {
		if ($.inArray($(this).attr('name'), requiredContactFields) > -1) {
			if ($(this).val() == '') {
				$(this).parent().addClass('error');
				isValid = 0;
			}
		}
	});

	if (isValid == 1) {
		return true;
	} else {
		return false;
	}

}


function submitForm(url, data, type, form) {
    form.find('button').html(`<i class="fal fa-spinner fa-spin"></i>`);
    form.find('button').attr('disabled','disabled');
	$.ajax({
		type : type,
		url: url,
		headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
		data : data,
		dataType : 'json',
		processData: false,
		success : function(data) {
			var title = data.notifTitle;
			var message = (_.isUndefined(data.notifMessage) ? '' : data.notifMessage);
            swal({title: "",
                text: 'Your enquiry has been successfully sent. One of our team members will get back to you in 24-48 hours. For any urgent enquiries, please call (03) 9369 9468.',
                type: 'success',
                confirmButtonText: "OK",
                closeOnConfirm: false,
                showCancelButton: false
            });
			if (data.resetForm) {
				resetForm(form);
			}

			if (! _.isUndefined(data.redirect)) {
				setTimeout(function() {
					window.location = data.redirect;
				}, 3000);
			}

			if (! _.isUndefined(data.links)) {
				var links = JSON.parse(data.links);
				for (var i = links.length - 1; i >= 0; i--) {
					console.log(links[i]);
					window.open(links[i], '_blank');
				}
			}

            form.find('button').removeAttr('disabled');
            form.find('button').html(`SUBMIT`);
		},
		error : function(data, text, error) {
			var message = '';
			_.each(data.responseJSON, function(val) {
				message += val + ' ';
			});
            swal({title: "",
                text: 'Email sending failed.',
                type: 'error',
                confirmButtonText: "OK",
                closeOnConfirm: false,
                showCancelButton: false
            });
			
            form.find('button').removeAttr('disabled');
            form.find('button').html(`SUBMIT`);
		}
	});
};

function resetForm(form) {
	form.find('input[type!="hidden"]').val('');
	form.find('textarea').val('');
};

$( document ).ready(function() {
    $('a[data-file]').each(function() {
        $(this).attr('target', '_blank');
    });

    var el = document.getElementById('gallery-container');
    swipedetect(el, function(swipedir){
        if(swipedir == 'left') {
            
            $('.gallery-items.left').slick('slickPrev');
            $('.gallery-items.right').slick('slickNext');
        }
        else if(swipedir == 'right') {
            $('.gallery-items.left').slick('slickNext');
            $('.gallery-items.right').slick('slickPrev');
        }
        else {

        }
    });
});


function swipedetect(el, callback){
  
    var touchsurface = el,
    swipedir,
    startX,
    startY,
    distX,
    distY,
    threshold = 75, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 500, // maximum time allowed to travel that distance
    elapsedTime,
    startTime,
    handleswipe = callback || function(swipedir){}
  
    if (touchsurface) {
        touchsurface.addEventListener('touchstart', function(e){
            var touchobj = e.changedTouches[0]
            swipedir = 'none'
            dist = 0
            startX = touchobj.pageX
            startY = touchobj.pageY
            startTime = new Date().getTime() // record time when finger first makes contact with surface
            
            // e.preventDefault()
        }, false)
    
        touchsurface.addEventListener('touchmove', function(e){
            // console.log(e);
            // e.preventDefault() // prevent scrolling when inside DIV
        }, false)
    
        touchsurface.addEventListener('touchend', function(e){
            var touchobj = e.changedTouches[0]
            distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
            distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
            elapsedTime = new Date().getTime() - startTime // get time elapsed
            if (elapsedTime <= allowedTime){ // first condition for awipe met
                if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
                    swipedir = (distX < 0)? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
                }
                else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
                    swipedir = (distY < 0)? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
                }
            }
            handleswipe(swipedir)
            // e.preventDefault()
        }, false)
    }
}

